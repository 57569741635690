"use client";
import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Link from "next/link";
import TeacherCard from "../../shared/TeacherCard";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import USER, { SelectTeachers } from "@/redux/User";
import MyLink from "@/components/shared/myLink/MyLink";

const Index = ({
  full = true,
  teachers,
  loading,
}: {
  full?: boolean;
  teachers: any;
  loading: boolean;
}) => {
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(true);

  // const teachers11 = useAppSelector(SelectTeachers);
  // console.log("teachers11", teachers11);
  // console.log("teachersP", teachers);

  // useEffect(() => {
  //   dispatch(USER.thunks.doGetTeachers({ search: "", school_years: [] }));
  //   // setLoading(false);
  // }, []);

  return (
    <div id="instructors" className="container my-20">
      {full && (
        <>
          <p
            className="titleHeading"
          //  data-aos="fade-up"
          >
            مدرسين زي الفل.. ومستنيينك!
          </p>
          <p
            className="text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10"
          // data-aos="fade-up"
          >
            هتلاقي كل واحد فيهم جاهز يساعدك تذاكر من غير تعقيد، اختار المدرس اللي يناسبك وابدأ رحلتك الدراسية مع الكورس اللي هيخليك تقول:
            "أهو كده المذاكرة بقت سهلة!"
          </p>
        </>
      )}
      {loading ? (
        <SkeletonTheme baseColor="#695750" highlightColor="#6b6e72">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="p-[20px] bg-[#5F4D46] rounded-[20px]"
                >
                  <Skeleton height={280} className="rounded-[20px]" />
                  <Skeleton height={20} width="50%" className="my-3" />
                  <Skeleton height={20} width="80%" />
                  <Skeleton height={50} width="90%" className="mt-3" />
                </div>
              ))}
          </div>
        </SkeletonTheme>
      ) : teachers && teachers.length > 0 ? (
        <Carousel opts={{ direction: "rtl", align: "center" }}>
          <CarouselContent className="pb-10">
            {teachers.map((teacher: any) => (
              <CarouselItem
                className="md:basis-1/2 xl:basis-1/4"
                key={teacher.id}
              >
                <TeacherCard
                  id={teacher?.id}
                  image={teacher?.image}
                  subject={teacher?.subject?.name}
                  teacherName={teacher?.name}
                  description={teacher?.description}
                  schoolYears={teacher?.school_years}
                  full={full}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div
            className="flex justify-center items-center gap-3"
          // data-aos="fade-up"
          >
            <CarouselPrevious className="bg-[#5F4D46] border-[#5F4D46] hover:bg-[#5F4D46]" />
            {full && (
              <MyLink
                href="/instructors"
                className="bg-[#5F4D46] text-16 text-white font-[400] py-[10px] px-[40px] rounded-[8px]"
              >
                عرض الكل
              </MyLink>
            )}
            <CarouselNext className="bg-[#5F4D46] border-[#5F4D46] hover:bg-[#5F4D46]" />
          </div>
        </Carousel>
      ) : (
        full && (
          <div className="text-center text-red-400 bg-[#695750] rounded-[5px] py-5 px-3">
            <p>لم يتم العثور على مدرسين.</p>
          </div>
        )
      )}
    </div>
  );
};

export default Index;
