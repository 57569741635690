"use client";
import Link from "next/link";
import React, { useState } from "react";

import { useAppSelector } from "@/redux/store";
import { SelectLoggedUser } from "@/redux/Auth";
import MyLink from "@/components/shared/myLink/MyLink";
const index = ({ home }: any) => {
  const [showFilter, setshowFilter] = useState(false);
  const loggedUser = useAppSelector(SelectLoggedUser);

  return (
    <div className=" container my-10">
      <p className=" text-70 text-white font-[700] text-center mb-5">
        جاهز تبقى <span className=" text-primaryColor">نجم دفعتك؟</span>
      </p>
      <p className=" text-26 text-textColor max-w-[1390px] mx-auto text-center">
        داون تاون هو المكان اللي هيشيل عنك هم الدراسة ويخليك تركز على النجاح بأسلوب بسيط ولذيذ، من أول الكورسات اللي هتساعدك تفهم كل حاجة، لحد النصايح اللي هتخليك تخلص المذاكرة وأنت مبسوط، وكمان إزاى تحل الامتحان وانت مطمن!
      </p>
      <h2 className=" text-26 max-w-[1390px] mx-auto text-center text-primaryColor">يلا ما تضيعش وقت!
      </h2>
      {loggedUser.isLoggedIn && loggedUser.userToken ? (
        <div className="flex items-center justify-center flex-wrap mt-2 gap-5">
          <MyLink
            href={"/courses"}
            className="text-13 font-[500] text-white py-2 px-5 border border-white
           rounded-[7px]"
            style={{
              background:
                "linear-gradient(44deg, rgba(0, 0, 0, 0.08) 37.32%, rgba(0, 0, 0, 0.00) 50.98%), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          >
            عرض الكورسات
          </MyLink>
          <MyLink
            href={"/books"}
            className="text-13 font-[500] text-white py-2 px-5 border border-white
           rounded-[7px]"
            style={{
              background:
                "linear-gradient(44deg, rgba(0, 0, 0, 0.08) 37.32%, rgba(0, 0, 0, 0.00) 50.98%), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
            }}
          >
            شراء كتب
          </MyLink>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-2 gap-5">
          <MyLink
            href={"/courses"}
            className=" text-13 font-[500] text-white py-2 px-5 border border-white
            rounded-[7px] bg-gradient-to-b from-[#5F4D4627] to-[#5F4D4666]"
          >
              افتح حسابك حالًا وابدأ اختار كورساتك!
          </MyLink>
        </div>
      )}
    </div>
  );
};

export default index;
