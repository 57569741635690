'use client';
import React from 'react';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import Link from 'next/link';
import MyLink from '@/components/shared/myLink/MyLink';

const index = () => {
	return (
		<div id='FAQ' className=' container my-20'>
			<p
				className='titleHeading'
				// data-aos="fade-up"
			>
				الأسئلة اللي دايمًا بنسمعها!
			</p>
			<p
				className=' text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10'
				// data-aos="fade-up"
			>
				هنا هتلاقي كل الإجابات اللي بتدور عليها لو عندك سؤال، ممكن تفتحه وتشوف
				الرد بكل سهولة من فريق الدعم الفني.
			</p>
			<Accordion
				type='single'
				collapsible
				className=' flex flex-col gap-5'
				defaultValue='item-1'>
				<AccordionItem
					value='item-1'
					className=' bg-[#5F4D46] py-[15px] px-[20px] rounded-[20px]'
					// data-aos="fade-up"
				>
					<AccordionTrigger className=' text-20 text-white font-[500]'>
						إزاي تتواصل مع الدعم الفني؟
					</AccordionTrigger>
					<AccordionContent className=' text-16 text-textColor font-[500]'>
						لو عندك مشكلة أو استفسار، تقدر تكلمنا على طول من خلال{' '}
						<MyLink
							href={'https://wa.me/+201120456472'}
							className='text-primaryColor'
							target='_blank'>
							واتساب،
						</MyLink>{' '}
						أو حتى{' '}
						<MyLink
							href={
								'https://www.facebook.com/profile.php?id=61565900266630&mibextid=LQQJ4d'
							}
							className=' text-primaryColor'
							target='_blank'>
							فيسبوك،
						</MyLink>
						اضغط على "تواصل معانا" واحنا هنرد عليك في أسرع وقت! (بس تصبر شوية)
					</AccordionContent>
				</AccordionItem>
				{/* <AccordionItem
          value="item-2"
          className=" bg-[#5F4D46] py-[15px] px-[20px] rounded-[20px]"
          data-aos="fade-up"
        >
          <AccordionTrigger className=" text-20 text-white font-[500]">
            ما هي وسائل الدفع المتاحة
          </AccordionTrigger>
          <AccordionContent className=" text-16 text-textColor font-[500]">
            يمكنك شراء الحصص عن طريق فوري / أمان / اي محفظه إلكترونية / فيزا
          </AccordionContent>
        </AccordionItem> */}
			</Accordion>
		</div>
	);
};

export default index;
