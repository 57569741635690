"use client";
import MyLink from "@/components/shared/myLink/MyLink";
import Link from "next/link";
import React from "react";

const index = () => {
  return (
    <div className="container">
      <div className="bg-primaryColor h-[321px] 2xl:h-[489px] rounded-[36px] flex flex-col items-center justify-center gap-10 2xl:gap-5 my-20 ">
        <p className=" text-45 text-[#ffffff] font-[700]">جاهز تحقق أهدافك؟</p>
        <p className=" text-30 text-[#ffffff] font-[500] max-w-[900px] text-center">
          داون تاون مش مجرد منصة تعليمية، دي خطوتك الأولى للنجاح!
          هتلاقي كل الأدوات اللي هتساعدك تركز وتتفوق في كل المواد.
        </p>
        <h2 className=" text-26 max-w-[1390px] mx-auto text-center text-textColor">يلا، ابدأ دلوقتي!
        </h2>
        <MyLink
          href={"/signup"}
          className="text-16 text-[#000000] font-[600] bg-white shadow-[0px_4px_134px_0px_#FFF] rounded-[10px] py-3 px-20"
        >
          أنشئ حسابك وخليك مستعد للتميز!
        </MyLink>
      </div>
    </div>
  );
};

export default index;
