"use client";
import React from "react";
import starB from "@/public/images/starB.svg";
import Image from "next/image";

const Card = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div
      className=" bg-[#5F4D46] rounded-[15px] py-[60px] px-[20px] flex flex-col items-center justify-center gap-6"
    // data-aos="fade-up"
    >
      <div className=" bg-primaryColor border-[3px] border-[#F958214D] w-[55px] h-[55px] flex items-center justify-center rounded-full">
        <Image src={starB} alt="starB" />
      </div>
      <p className="text-24 font-bold text-white">{title}</p>
      <p className="text-16 text-textColor font-[300] max-w-[410px] text-center">
        {description}
      </p>
    </div>
  );
};
const index = () => {
  return (
    <div id="advantages" className="container my-20 ">
      <p
        className="titleHeading"
      // data-aos="fade-up"
      >
        ليه تختار داون تاون؟
      </p>
      <p
        className=" text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10"
      // data-aos="fade-up"
      >
        يوجد الكثير من المميزات لتسهيل الدراسة علي الطالب وجعله مُستفادًا - هذه
        أهم مميزات المنصة ويوجد الكثير من المميزات يمكنك إكتشافها معنا .
      </p>
      <div className="  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <Card
          title="كورسات مجانية"
          description=" يتم عمل كورسات مجانية في بعض المواد لجميع الطلاب المشتركة في المنصة"
        />
        <Card
          title="المتابعه الدورية"
          description="يتم متابعة الطالب كل أسبوع لمنع تراكم الحصص"
        />
        <Card
          title="اكبر بنك اسئلة"
          description="تحتوي المنصة على اكبر بنك اسئله في جميع المواد من جميع المصادر"
        />
        <Card
          title="سهولة التواصل"
          description="يمكنك التواصل مع الدعم الفني 24 ساعه لحل اي مشكلة"
        />
        <Card
          title="امتحانات وتدريبات"
          description="عمل امتحانات دوريه لتدريب الطالب علي امتحان اخر السنة"
        />
        <Card
          title="مسابقات يومية وشهرية"
          description="هيكون في مسابقات يومية وشهرية في المنصة لخلق روح التنافس بين الطلاب
"
        />
      </div>
    </div>
  );
};

export default index;
